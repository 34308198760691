<template>
  <activity-list :listType="listType"></activity-list>
</template>

<script>
import activityList from '../activityList'
export default {
  name:"",
  components:{
    activityList
  },
  props:{},
  data(){
    return {
      listType: '',
      refresh: true
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.listType = 'check'
  },  
  computed:{},
  methods:{}
}
</script>

<style lang="scss" scoped>
  
</style>